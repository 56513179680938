/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement("section", null, React.createElement("div", null, React.createElement(_components.h2, null, "Les meilleurs quartiers pour se loger à Helsinki"), React.createElement("p", null, "Pour se loger, Helsinki s’articule en cinq quartiers principaux :"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/fi/helsinki/kamppi.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Kamppi"), " : quartier central abritant de nombreux hôtels et le centre commercial Kamppi."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/fi/helsinki/helsinkicitycentre.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Kluuvi"), ", à l’est : centre-ville d’Helsinki et quartier commerçant autour de la gare centrale."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/fi/helsinki/punavuori.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Punavuori"), ", au sud : quartier branché avec de nombreuses galeries d’art, des bars et des restaurants."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/fi/helsinki/etutoolo.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Töölö"), ", au nord : quartier calme et résidentiel, autour du parc Sibelius."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/fi/helsinki/kallio.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Kallio"), ", au nord-est : quartier bohème en voie de gentrification."), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
